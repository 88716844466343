import React, {useEffect, useState} from 'react';
import {useNavigate, useParams, useLocation, Location} from "react-router-dom";
import {Spin, Tabs,} from "antd";
import {
    AndroidOutlined,
    AppleOutlined,
    SettingOutlined,
    ControlOutlined,
    ScheduleOutlined,
    InfoCircleOutlined,
    SlidersOutlined,
    MonitorOutlined,
    CloudDownloadOutlined,
} from "@ant-design/icons";
import {useUserData} from "../../../../../../Hooks/TT_ELD/Users";
import MainFields from "./MainFields";
import Commands from "./Commands";
import Config from "./Config";
import Logs from "../Logs";
import TruckInfo from "../TruckInfo/TruckInfo";
import LastTracking from "./LastTracking";
import Settings from "./Settings";
import Documents from "./Documents";
import UserCompanies from "../../../UserCompanies/UserCompanies";
import VehicleTabs from "../../Vehicles/VehicleData/VehicleEdit/VehicleTabs";
import useRouteQuery from "../../../../../../Utils/useRouterQuery";
import TrackingByRang from "./TrackingByRang";


interface Params {
    id: string | undefined;

    [key: string]: string | undefined;
}

type Data = {
    data: any
    isLoading?: boolean,
    isFetching?: boolean
    refetch(): void,
}

const User = () => {
    const [getTabKey, setTabKey] = useRouteQuery("1", 'tabKey', (v) => String(v));
    const [getTabKeyLocal, setTabKeyLocal] = useState('');
    useEffect(() => {
        setTabKeyLocal(getTabKey);
    }, [getTabKey])

    let navigate = useNavigate();
    const location: Location = useLocation()
    const {id} = useParams<Params>();
    const {data, isLoading, isFetching}: Data = useUserData(id);
    const items = [
        {
            label: 'Main fields',
            key: '1',
            children:<MainFields/>,
            icon :<AppleOutlined rev={undefined}/>
        },
        {
            label: <span>Commands</span>,
            key: '2',
            children: <Commands/>,
            icon :<AndroidOutlined rev={undefined}/>

        },
        {
            label: <span>Config</span>,
            key: '3',
            children: <Config/>,
            icon :<ControlOutlined rev={undefined}/>

        },
        // {
        //     label: <span>Settings</span>,
        //     key: '4',
        //     children: <Settings/>,
        //     icon :<SettingOutlined rev={undefined}/>
        // },
        {
            label: <span>Logs</span>,
            key: '4',
            children: <Logs/>,
            icon :<ScheduleOutlined rev={undefined}/>

        },
        {
            label: <span>Truck info</span>,
            key: '5',
            children: <TruckInfo/>,
            icon :<InfoCircleOutlined rev={undefined}/>

        },
        {
            label: <span>Vehicle</span>,
            key: '6',
            children: <VehicleTabs vehicleId={data?.vehicleId}/>,
            icon :<SlidersOutlined rev={undefined}/>

        },
        {
            label: <span>Last tracking</span>,
            key: '7',
            children: <LastTracking/>,
            icon :<MonitorOutlined rev={undefined}/>

        },
        {
            label: <span>Documents</span>,
            key: '8',
            children: <Documents/>,
            icon :<CloudDownloadOutlined rev={undefined}/>
        },
        // {
        //     label: <span>Tracking By Range</span>,
        //     key: '9',
        //     children: <TrackingByRang/>,
        //     icon :<CloudDownloadOutlined rev={undefined}/>
        // },
    ]
    const items2 = [
        {
            label: <span>Main fields</span>,
            key: '1',
            children:<MainFields/>,
            icon :<AppleOutlined rev={undefined}/>

        },
        {
            label: <span>Commands</span>,
            key: '2',
            children: <Commands/>,
            icon :<AndroidOutlined rev={undefined}/>

        },
        {
            label: <span>Config</span>,
            key: '3',
            children: <Config/>,
            icon :<ControlOutlined rev={undefined}/>

        },
        {
            label: 'Company',
            key: '4',
            children: <UserCompanies uid={id}/>,
            icon :<CloudDownloadOutlined rev={undefined}/>

        },
    ]
    const getTabs = data?.role === 'driver' ? items : items2



    return (
            <Spin style={{marginTop:200}} size="large" spinning={isLoading || isFetching || !getTabKeyLocal}>
                <div style={{padding: "12px 8px"}}>
                {data && getTabKeyLocal && <Tabs
                    onTabClick={(e) => {
                        navigate(`/user/users/${id}?tabKey=${e}`);
                        setTabKey(e);
                    }}
                    defaultActiveKey={location.search.split('').splice(8, 9)[0] || '1'}
                    style={{height: '100%'}}
                    items={getTabs.map((tab) => {
                        return {
                            label: tab.label,
                            key: tab.key,
                            children: tab.children,
                            icon : tab?.icon
                        };
                    })}
                />}
                </div>
            </Spin>
    );
};

export default User;